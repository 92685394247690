<template>
    <section class="encuestas-index ml-4 mt-4">
        <cargando v-if="cargando" />
        <div class="row justify-content-lg-end d-middle-center">
            <div class="col-7">
                <p class="col-auto f-20 f-500">Encuestas</p>
            </div>
            <div class="col-2 justify-content-lg-end">
                <button class="btn-general btn btn-sm f-14" @click="crearNuevaEncuesta">
                    Nueva encuesta
                </button>
            </div>
            <div class="col-3">
                <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" class="mx-xl-n5" @change="buscar" />
            </div>
        </div>
        <!-- tabla -->
        <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 180px)">
            <el-table :data="encuestas" height="calc(100vh - 143px)" style="width: 100%" @row-click="verEncuesta">
                <el-table-column sortable prop="nombre" label="Nombre" />
                <el-table-column sortable prop="fechaVigencia" label="Vigencia">
                    <template slot-scope="scope">
                        <div class="">
                            <p v-if="scope.row.fecha_inicio">{{ scope.row.fecha_inicio | helper-fecha('DD dddd MMM YYYY') }} - {{ scope.row.fecha_fin | helper-fecha('DD dddd MMM YYYY') }}</p>
                            <p v-else> - </p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="cant_respuestas" label="Repuestas" width="120" align="center" />
                <el-table-column sortable prop="total_encuestados" label="Posibles encuestados" align="center" />
                <el-table-column sortable prop="alcance" label="Alcance" width="120" align="center">
                    <template slot-scope="scope">
                        <div class="">
                            <p> {{ scope.row.res_porcentaje }} % </p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="estado" width="120">
                    <template slot-scope="scope">
                        <div class="">
                            <p v-show="scope.row.estado === 0" class="estado-inactiva br-4 px-2 f-14 text-center">Inactiva</p> 
                            <p v-show="scope.row.estado === 1" class="estado-activa br-4 px-2 f-14 text-center">Activa</p> 
                            <p v-show="scope.row.estado === 2" class="estado-finalizado br-4 px-2 f-14 text-center">Finalizada</p> 
                            <p v-show="scope.row.estado === 3" class="estado-borrador br-4 px-2 f-14 text-center">Borrador</p> 
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="row">
                    <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                    </div>
                </div>
                <infinite-loading
                slot="append"
                force-use-infinite-wrapper=".el-table__body-wrapper"
                @infinite="listar"
                />
            </el-table>
            <!-- <div class="col-12">
            </div> -->
        </div>
    </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';

import Encuestas from '~/services/encuestas'
export default {
    components:{
        InfiniteLoading
    },
    data(){
        return{
            page:1,
            encuestas:[],
            search: null,
            cargando: false
        }
    },
    methods:{
        crearNuevaEncuesta(){
            this.$router.push({ name: 'encuestas.crear' })
        },
        verEncuesta(row){
            if(row.estado == 3) this.$router.push({name: 'encuestas.crear.continuar',params:{id_encuesta: row.id}});
            else this.$router.push({ name:'encuestas.ver', params:{id_encuesta : row.id} })
        },
        buscar(){
            this.page = 1
            this.encuestas = []
            this.listar()
        },
        async listar($state){
            try {
                this.cargando = true
                const state = $state
                const params={
                    search : this.search,
                    page: this.page
                }
                const {data} = await Encuestas.listar(params)
                if(data.data.data.length){
                    this.page += 1;
                    this.encuestas.push(...data.data.data)
                    if(typeof state !== 'undefined'){
                        state.loaded();
                    }
                }else{
                    if(typeof state !== 'undefined'){
                        state.complete();
                    }
                }
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.encuestas-index{
    .estado{
        &-borrador{
          background: #80DCFF;
        }
        &-activa{
          background: #93EDCF;
        }
        &-inactiva{
          background: #FFB2C2;
        }
        &-finalizado{
          background: #000000;
          color: #FFFFFF;
        }
    }
}
</style>